<template>
  <a-spin :spinning="confirmLoading">
    <j-form-container :disabled="formDisabled">
      <a-form-model ref="form" :model="model" :rules="validatorRules" slot="detail">
        <a-row>
          <a-col :span="24">
            <a-form-model-item label="任务标题" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="title">
              <a-input v-model="model.title" placeholder="请输入任务标题"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="任务分类" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="categoryId">
              <j-dict-select-tag type="list"  v-model="model.categoryId" dictCode="task_category where is_enable=1,name,id" placeholder="请选择任务分类" />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="班级" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="classes">
              <a-select allowClear mode="multiple" v-model="model.classes" :options="classData"></a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="所需设备" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="asstes">
              <a-select mode="multiple" v-model="model.asstes" :options="categoryList"></a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="任务描述" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="description">
              <a-textarea v-model="model.description" rows="4" placeholder="请输入任务描述" />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="开始时间" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="startTime">
              <j-date placeholder="请选择开始时间"  v-model="model.startTime" :show-time="true" date-format="YYYY-MM-DD HH:mm:ss" style="width: 100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="结束时间" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="endTime">
              <j-date placeholder="请选择结束时间"  v-model="model.endTime" :show-time="true" date-format="YYYY-MM-DD HH:mm:ss" style="width: 100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
                <a-form-model-item
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
                label="实验报告模版"
                hasFeedback>
                <j-upload v-model="model.formworkUrl" :number="1"></j-upload>
              </a-form-model-item>
          </a-col>
          <a-col :span="24">
                <a-form-model-item
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
                label="资源文件"
                hasFeedback>
                <j-upload v-model="model.resources" :number="3"></j-upload>
              </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="发布状态" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="isPublish">
              <j-dict-select-tag type="list" v-model="model.isPublish" dictCode="send_status" placeholder="请选择发布状态" disabled/>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="发布时间" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="publishTime">
              <j-date placeholder="请选择发布时间"  v-model="model.publishTime" :show-time="true" date-format="YYYY-MM-DD HH:mm:ss" style="width: 100%" disabled/>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="isEnable">
              <j-dict-select-tag type="radio" v-model="model.isEnable" dictCode="status" placeholder="请选择状态" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </j-form-container>
  </a-spin>
</template>

<script>

  import { httpAction, getAction } from '@/api/manage'
  import { validateDuplicateValue } from '@/utils/util'
  import JUpload from '@/components/jeecg/JUpload'
  import store from '@/store'
  export default {
    name: 'TaskForm',
    components: {
    JUpload,
  },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data () {
      return {
        store,
        categoryList:[],
        classData:[],
        model:{
          resources:[],
          formworkUrl:''
         },
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules: {
           title: [
              { required: true, message: '请输入任务标题!'},
           ],
           classes: [
              { required: true, message: '请选择班级!'},
           ],
           categoryId: [
              { required: true, message: '请选择任务分类!'},
           ],
           asstes: [
              { required: true, message: '请选择设备!'},
           ],
           isEnable: [
              { required: true, message: '请输入状态!'},
           ],
        },
        url: {
          add: "/ylpp/task/add",
          edit: "/ylpp/task/edit",
          queryById: "/ylpp/task/queryById",
          classPulldownUrl: 'ylpp/teacher/classes',
          categoryPulldownUrl: 'ylpp/assets/pulldown',
          newsView: "ylpp/task/queryById"
        }
      }
    },
    computed: {
      formDisabled(){
        return this.disabled
      },
    },
    created () {
      this.renderCategory();
      this.getClass();
       //备份model原始值
      this.modelDefault = JSON.parse(JSON.stringify(this.model));
    },
    methods: {
      add () {
        this.edit(this.modelDefault);
      },
      edit (record) {
        //this.model = Object.assign({}, record);
        this.visible = true;
        this.viewNews(record)
      },
      viewNews(record){
        getAction(this.url.newsView,{id:record.id}).then((res)=>{
             record.classes = res.result.classes;
             record.asstes = res.result.asstes;
             record.resources = res.result.resources;
             this.model = Object.assign({}, record);
          })
      },
      submitForm () {
        const that = this;
        // 触发表单验证
        this.$refs.form.validate(valid => {
          if (valid) {
            if(typeof this.model.resources=='string'&&this.model.resources){
              this.model.resources=this.model.resources.split(',')
            }
            if(!this.model.resources){
              this.model.resources=[];
            }
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
               method = 'put';
            }
            httpAction(httpurl,this.model,method).then((res)=>{
              if(res.success){
                that.$message.success(res.message);
                that.$emit('ok');
              }else{
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
            })
          }

        })
      },getClass(name) {
       // var userId = store.getters.userInfo.id;
      httpAction(`${this.url.classPulldownUrl}`, {}, 'get')
        .then((res) => {
          if (res.success) {
            this.classData=res.result;
            this.classData.forEach((item) => {
              item.title = item.name
              item.value = item.id
            })
          } else {
          }
        })
        .finally(() => {
          this.confirmLoading = false;
        })
    },renderCategory() {
      let that = this
      httpAction(`${this.url.categoryPulldownUrl}?name=`, {}, 'get')
        .then((res) => {
          if (res.success) {
            res.result.forEach((item)=>{
              item.value=item.id;
              item.label=item.name;
              //item.text=item.name;
            })
            this.categoryList=res.result;
          } else {
          }
        })
        .finally(() => {
          this.confirmLoading = false;
        })
    },
    handleSearch(value) {
      this.getClass(value)
    }
    }
  }
</script>