var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.confirmLoading } },
    [
      _c(
        "j-form-container",
        { attrs: { disabled: _vm.formDisabled } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                slot: "detail",
                model: _vm.model,
                rules: _vm.validatorRules
              },
              slot: "detail"
            },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "任务标题",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "title"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入任务标题" },
                            model: {
                              value: _vm.model.title,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "title", $$v)
                              },
                              expression: "model.title"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "任务分类",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "categoryId"
                          }
                        },
                        [
                          _c("j-dict-select-tag", {
                            attrs: {
                              type: "list",
                              dictCode:
                                "task_category where is_enable=1,name,id",
                              placeholder: "请选择任务分类"
                            },
                            model: {
                              value: _vm.model.categoryId,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "categoryId", $$v)
                              },
                              expression: "model.categoryId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "班级",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "classes"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              allowClear: "",
                              mode: "multiple",
                              options: _vm.classData
                            },
                            model: {
                              value: _vm.model.classes,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "classes", $$v)
                              },
                              expression: "model.classes"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "所需设备",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "asstes"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              mode: "multiple",
                              options: _vm.categoryList
                            },
                            model: {
                              value: _vm.model.asstes,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "asstes", $$v)
                              },
                              expression: "model.asstes"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "任务描述",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "description"
                          }
                        },
                        [
                          _c("a-textarea", {
                            attrs: { rows: "4", placeholder: "请输入任务描述" },
                            model: {
                              value: _vm.model.description,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "description", $$v)
                              },
                              expression: "model.description"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "开始时间",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "startTime"
                          }
                        },
                        [
                          _c("j-date", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请选择开始时间",
                              "show-time": true,
                              "date-format": "YYYY-MM-DD HH:mm:ss"
                            },
                            model: {
                              value: _vm.model.startTime,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "startTime", $$v)
                              },
                              expression: "model.startTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "结束时间",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "endTime"
                          }
                        },
                        [
                          _c("j-date", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请选择结束时间",
                              "show-time": true,
                              "date-format": "YYYY-MM-DD HH:mm:ss"
                            },
                            model: {
                              value: _vm.model.endTime,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "endTime", $$v)
                              },
                              expression: "model.endTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "实验报告模版",
                            hasFeedback: ""
                          }
                        },
                        [
                          _c("j-upload", {
                            attrs: { number: 1 },
                            model: {
                              value: _vm.model.formworkUrl,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "formworkUrl", $$v)
                              },
                              expression: "model.formworkUrl"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "资源文件",
                            hasFeedback: ""
                          }
                        },
                        [
                          _c("j-upload", {
                            attrs: { number: 3 },
                            model: {
                              value: _vm.model.resources,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "resources", $$v)
                              },
                              expression: "model.resources"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "发布状态",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "isPublish"
                          }
                        },
                        [
                          _c("j-dict-select-tag", {
                            attrs: {
                              type: "list",
                              dictCode: "send_status",
                              placeholder: "请选择发布状态",
                              disabled: ""
                            },
                            model: {
                              value: _vm.model.isPublish,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "isPublish", $$v)
                              },
                              expression: "model.isPublish"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "发布时间",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "publishTime"
                          }
                        },
                        [
                          _c("j-date", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请选择发布时间",
                              "show-time": true,
                              "date-format": "YYYY-MM-DD HH:mm:ss",
                              disabled: ""
                            },
                            model: {
                              value: _vm.model.publishTime,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "publishTime", $$v)
                              },
                              expression: "model.publishTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "状态",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "isEnable"
                          }
                        },
                        [
                          _c("j-dict-select-tag", {
                            attrs: {
                              type: "radio",
                              dictCode: "status",
                              placeholder: "请选择状态"
                            },
                            model: {
                              value: _vm.model.isEnable,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "isEnable", $$v)
                              },
                              expression: "model.isEnable"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }