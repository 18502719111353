<template>
  <a-spin :spinning="confirmLoading">
    <j-form-container :disabled="formDisabled">
      <a-form-model ref="form" :model="model" :rules="validatorRules" slot="detail">
        <a-row>
          <a-col :span="24">
            <a-form-model-item label="院系" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="departmentId">
              <a-select allowClear v-model="model.departmentId" :options="departmentList"></a-select>
<!--              <j-dict-select-tag type="list" placeholder="请输入院系名称" v-model="departmentId"
               :dictCode="`base_department where is_enable=1 and school_id_code=${schoolIdCode},name,id`"
              />-->
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="专业编码" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="code">
              <a-input v-model="model.code" placeholder="请输入专业编码"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="专业名称" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="name">
              <a-input v-model="model.name" placeholder="请输入专业名称"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="isEnable">
              <j-dict-select-tag type="radio" v-model="model.isEnable" dictCode="status" placeholder="请选择状态" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </j-form-container>
  </a-spin>
</template>

<script>

  import { httpAction, getAction } from '@/api/manage'
  import { validateDuplicateValue } from '@/utils/util'

  export default {
    name: 'BaseMajorForm',
    components: {
    },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      },
      schoolIdCode: {
        type: String,
        default: "",
        required: true
      },
      departmentId: {
        type: String,
        default: "",
        required: true
      },
    },
    data () {
      return {
        departmentList:'',
        model:{
         },
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules: {
           departmentId: [
              { required: true, message: '请输入院系!'},
           ],
           code: [
              { required: true, message: '请输入专业编码!'},
             // { validator: (rule, value, callback) => validateDuplicateValue('base_major', 'code', value, this.model.id, callback)},
           ],
           name: [
              { required: true, message: '请输入专业名称!'},
           ],
           isEnable: [
              { required: true, message: '请输入状态!'},
           ],
        },
        url: {
          add: "/ylpp/baseMajor/add",
          edit: "/ylpp/baseMajor/edit",
          queryById: "/ylpp/baseMajor/queryById",
          baseDepartmentAll: 'ylpp/baseDepartment/list'
        }
      }
    },
    computed: {
      formDisabled(){
        return this.disabled
      },
    },
    created () {
       //备份model原始值
      this.modelDefault = JSON.parse(JSON.stringify(this.model));
    },
    methods: {
      baseDepartmentAll() {
      this.departmentList = []
      let params = {
        code: '',
        id: '',
        name: '',
        pageNo: '1',
        pageSize: '100',
        schoolIdCode: this.schoolIdCode,
        tenantId: '',
      }
      getAction(this.url.baseDepartmentAll, params).then((res) => {
        if (res.success) {
          this.departmentList=res.result.records;
          this.departmentList.forEach((item) => {
              item.title = item.name
              item.value = item.id
            })
        }
      })
    },
      add () {
        this.edit(this.modelDefault);
      },
      edit (record) {
        this.baseDepartmentAll();
        this.model = Object.assign({}, record);
        if(!!!record){
          this.model.isEnable = 1;
        }
        this.visible = true;
      },
      submitForm () {
        const that = this;
        this.model.departmentId=this.departmentId;
        this.model.schoolIdCode=this.schoolIdCode;
        // 触发表单验证
        this.$refs.form.validate(valid => {
          if (valid) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
               method = 'put';
            }
            httpAction(httpurl,this.model,method).then((res)=>{
              if(res.success){
                that.$message.success(res.message);
                that.$emit('ok');
              }else{
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
            })
          }
         
        })
      },
    }
  }
</script>