var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-skeleton",
    { attrs: { active: "", loading: _vm.loading, paragraph: { rows: 17 } } },
    [
      _c(
        "a-card",
        { staticClass: "card-area", attrs: { bordered: false } },
        [
          _c("a-alert", { attrs: { type: "info", showIcon: true } }, [
            _c(
              "div",
              { attrs: { slot: "message" }, slot: "message" },
              [
                _vm._v(
                  "\n        共追踪到 " +
                    _vm._s(_vm.dataSource.length) +
                    " 条近期HTTP请求记录\n        "
                ),
                _c("a-divider", { attrs: { type: "vertical" } }),
                _c("a", { on: { click: _vm.handleClickUpdate } }, [
                  _vm._v("立即刷新")
                ])
              ],
              1
            )
          ]),
          _c("a-table", {
            staticStyle: { "margin-top": "20px" },
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
              scroll: { x: 900 }
            },
            on: { change: _vm.handleTableChange },
            scopedSlots: _vm._u([
              {
                key: "timeTaken",
                fn: function(text) {
                  return [
                    text < 500
                      ? _c("a-tag", { attrs: { color: "green" } }, [
                          _vm._v(_vm._s(text) + " ms")
                        ])
                      : text < 1000
                      ? _c("a-tag", { attrs: { color: "cyan" } }, [
                          _vm._v(_vm._s(text) + " ms")
                        ])
                      : text < 1500
                      ? _c("a-tag", { attrs: { color: "orange" } }, [
                          _vm._v(_vm._s(text) + " ms")
                        ])
                      : _c("a-tag", { attrs: { color: "red" } }, [
                          _vm._v(_vm._s(text) + " ms")
                        ])
                  ]
                }
              },
              {
                key: "responseStatus",
                fn: function(text) {
                  return [
                    text < 200
                      ? _c("a-tag", { attrs: { color: "pink" } }, [
                          _vm._v(_vm._s(text) + " ")
                        ])
                      : text < 201
                      ? _c("a-tag", { attrs: { color: "green" } }, [
                          _vm._v(_vm._s(text) + " ")
                        ])
                      : text < 399
                      ? _c("a-tag", { attrs: { color: "cyan" } }, [
                          _vm._v(_vm._s(text) + " ")
                        ])
                      : text < 403
                      ? _c("a-tag", { attrs: { color: "orange" } }, [
                          _vm._v(_vm._s(text) + " ")
                        ])
                      : text < 501
                      ? _c("a-tag", { attrs: { color: "red" } }, [
                          _vm._v(_vm._s(text) + " ")
                        ])
                      : _c("span", [_vm._v(_vm._s(text))])
                  ]
                }
              },
              {
                key: "requestMethod",
                fn: function(text) {
                  return [
                    text === "GET"
                      ? _c("a-tag", { attrs: { color: "#87d068" } }, [
                          _vm._v(_vm._s(text))
                        ])
                      : text === "POST"
                      ? _c("a-tag", { attrs: { color: "#2db7f5" } }, [
                          _vm._v(_vm._s(text))
                        ])
                      : text === "PUT"
                      ? _c("a-tag", { attrs: { color: "#ffba5a" } }, [
                          _vm._v(_vm._s(text))
                        ])
                      : text === "DELETE"
                      ? _c("a-tag", { attrs: { color: "#f50" } }, [
                          _vm._v(_vm._s(text))
                        ])
                      : _c("span", [_vm._v(_vm._s(text) + " ms")])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }